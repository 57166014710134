import React from "react";

interface TableHeaderProps {
  titles: { name: string; key: string; width: string }[];
  flixHistoryType?: string
}

const TableHeader: React.FC<TableHeaderProps> = ({ titles, flixHistoryType }) => {
  return (
    <thead className="text-xs font-medium w-full rounded-lg text-left uppercase sticky  z-10 bg-white">
      <tr className="py-4 px-10 w-full">
        {titles.map((title, index) => (
          <th
            key={index}
            scope="col"
            className={`text-base p-4 ${
              index === 0 ? "rounded-l-full pl-12" : ""
            } ${index === titles.length - 1 ? "rounded-r-full" : ""} w-${
              index === 0 ? "1/3" : "1/4"
            } bg-bgPinkSecondary`}
          >
            {title.name}
          </th>
        ))}
      </tr>
    </thead>
  );
};

export default TableHeader;
